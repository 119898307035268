.heading {
  margin: 0.5rem 0;
  font-size: 16px;
  background: var(--main);
  padding: 5px;
  /* margin-bottom: 1rem; */
}
.main {
  gap: 1rem;
  display: grid;
  grid-template-columns: 20% 1fr;
  height: calc(98vh - 38px);
}

.tabBtn button {
  border: 1px solid var(--white);
  opacity: 1;
  height: auto;
  padding: 0.8em;
  font: normal normal medium 16px/20px Inter;
  letter-spacing: 0px;
  color: #233f48;
  opacity: 1;
  font-weight: bold;
  cursor: pointer;
}
.tabBtn .active {
  background: #fff;
}
.h40 {
  /* max-height: 25vh;
  overflow-y: auto;
  min-height: 25vh;
  margin-top: 1rem; */
}
.table {
  border-collapse: collapse;
  width: 100%;
}
td,
th {
  /* border: 1px solid #dddddd; */
  text-align: left;
  padding: 8px;
  font-size: 12px;
}
.boxShadow {
  box-shadow: rgb(86 84 84 / 20%) 0px 1px 2px 0px;
}
.save {
  gap: 1rem;
  display: flex;
  margin: 0.5rem 0;
  color: var(--blue);
  justify-content: flex-end;
}
.save svg {
  cursor: pointer;
  opacity: 0.8;
}
.save svg:hover {
  opacity: 1;
}
