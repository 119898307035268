.main {
  height: calc(100% - 90px);
  overflow-y: auto;
}
.file {
  height: 80vh;
  overflow-y: auto;
  gap: 1rem;
  display: grid;
  grid-template-columns: 40% 1fr;
}
.bg {
  border-radius: 4px;
}
.grid2 {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
}

.none {
  display: none;
}
.inputFile {
  margin-top: 0.5rem;
  /* background: #454c53; */
  padding: 12px;
  /* color: #fff; */
  border-radius: 4px;
  height: 150px;
  justify-content: center;
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  border: 1px solid var(--blue);
}
.inputFile label {
  font-size: 14px;
}

.heading {
  /* background: var(--main);
  padding: 1rem; */
  /* margin-bottom: 1rem; */
  /* border-radius: 4px; */
  /* color: var(--blue); */
  font-size: 1.3rem;
  /* box-shadow: 2px 1px 5px rgb(235 178 78 / 71%); */
}

.chat {
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.6) 0px 1px 15px;
  overflow-y: auto;
  height: calc(100vh - 120px);
}
.chatBody {
  overflow-y: auto;
  flex-grow: 1;
  margin-bottom: 1rem;
}
.chatBodyInner {
  margin-bottom: 1rem;
  padding: 1rem;
}
.inActive {
  opacity: 0.6;
  pointer-events: none;
}
