:root {
  --main: #f1f4f8;
  --white: #ffffff;
  --blue: #0c4590;
  --black: #050505;
  --textgray: #707070;
  --headingblue: #233f48;
  --btn: #165db9;
  --chat: #f7f5f5;
  --main-surface-primary: #f7f5f5;
}

html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
* {
  font-family: "Inter";
}
html {
  text-size-adjust: 100%;
  font-feature-settings: normal;
  font-family: "Inter";
  line-height: 1.5;
  tab-size: 4;
}
body {
  -webkit-font-smoothing: antialiased;
  counter-reset: katexEqnNo mmlEqnNo;
  /* background-color: var(--main-surface-primary); */
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #000;
  transition: background-color 5000s ease-in-out 0s;
  /* box-shadow: inset 0 0 20px 20px #23232329; */
}
