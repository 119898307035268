.home {
  height: calc(100vh - 48px);
  min-height: 74vh;
  margin: 0;
  display: grid;
  grid-template-rows: 50% 50%;
}
.homeright {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.2rem;
  background: #fff;
  overflow-y: auto;
  /* height: 95%; */
}
.homeright h4 {
  padding: 0.5rem 1rem;
  background: var(--main);
  border: 1px solid #fff;
}
.homemain {
  padding: 1.5rem;
  width: 60%;
  margin: 0 auto;
  overflow-y: auto;
}
.homemain h2 {
  font: normal normal 600 28px/34px Inter;
  letter-spacing: 0px;
  color: var(--black);
}
.homebox {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #165db966;
  border-radius: 15px;
  opacity: 1;
  display: grid;
  text-align: center;
  align-items: center;
  vertical-align: middle;
  padding: 1rem;
  cursor: pointer;
}

.homeupload {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  background: var(--main);
  padding: 1rem;
  /* height: 220px; */
  overflow-y: auto;
}
.recentChat {
  background: var(--main);
  padding: 1rem;
  margin: 0;
  height: 190px;
}

.recentActivity {
  background: var(--main);
  padding: 1rem;
  margin: 0;
  /* height: 220px; */
  text-align: left;
  overflow-y: auto;
}
.homesupport {
  text-align: center;
  font: normal normal normal 12px/15px Inter;
  letter-spacing: 0px;
  color: var(--textgray);
}
.homeup {
  text-align: center;
  font: normal normal normal 16px/23px Inter;
  letter-spacing: 0px;
  color: var(--black);
}
.homeclick {
  color: var(--blue);
}
.homebox:hover {
  background: var(--blue);
}

.homeupload .homebox:hover span {
  color: #fff !important;
}

.homeupload .homebox:hover svg path {
  stroke: #fff;
}
.homemain li {
  letter-spacing: 0px;
  color: #050505;
  opacity: 1;
  font-size: 16px;
  font-weight: 500;
  list-style-image: url("../../assets/BulletPoint.svg");
}
.homemain li ::marker {
  position: relative;
  top: 12px;
}
.list {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0.5rem;
  margin-bottom: 0.3rem;
  /* line-height: 1.3; */
}
.mt2 {
  margin-top: 1rem;
}
.homeBottom {
  display: flex;
  flex-direction: column-reverse;
}
.mainBg {
  background: var(--main);
}
