.btn {
  color: #fff;
  /* width: max-content; */
  opacity: 0.95;
  cursor: pointer;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  border: 1px solid var(--btn);
  display: flex;
  gap: 0.4rem;
  background: var(--btn) 0% 0% no-repeat padding-box;
  align-items: center;
}
.disbledbtn {
  color: #fff;
  /* width: max-content; */
  opacity: 0.4;
  cursor: pointer;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  pointer-events: none;
  border: 1px solid var(--btn);
  display: flex;
  gap: 0.4rem;
  background: var(--btn) 0% 0% no-repeat padding-box;
  align-items: center;
}
.btn-transparent {
  background: transparent;
}
.btn:hover {
  opacity: 1;
}
.m-2 {
  margin: 2rem;
  margin-top: 0;
}

button {
  text-transform: capitalize !important;
}
.text-right {
  text-align: -webkit-right;
  margin-bottom: 1rem;
  display: flex;
  gap: 0.4rem;
  justify-content: flex-end;
}
textarea {
  padding: 10px;
  text-align: justify;
}

.loader {
  width: 18px;
  aspect-ratio: 1;
  display: grid;
  border-radius: 50%;
  background: linear-gradient(
        0deg,
        rgb(0 0 0/50%) 30%,
        #0000 0 70%,
        rgb(0 0 0/100%) 0
      )
      50%/8% 100%,
    linear-gradient(90deg, rgb(0 0 0/25%) 30%, #0000 0 70%, rgb(0 0 0/75%) 0)
      50%/100% 8%;
  background-repeat: no-repeat;
  animation: l23 1s infinite steps(12);
}
.loader::before,
.loader::after {
  content: "";
  grid-area: 1/1;
  border-radius: 50%;
  background: inherit;
  opacity: 0.915;
  transform: rotate(30deg);
}
.loader::after {
  opacity: 0.83;
  transform: rotate(60deg);
}
@keyframes l23 {
  100% {
    transform: rotate(1turn);
  }
}
