.table {
  /* font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%; */
}
.table td,
th {
  /* border: 1px solid #dddddd; */
  text-align: left;
  /* padding: 8px; */
}
.table td {
  font: normal normal normal 14px/18px Inter;
  letter-spacing: 0px;
  color: #2d2d2d;
  max-width: 20ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.table th {
  background: #f1f4f8 0% 0% no-repeat padding-box;
  color: var(--blue);
  font: normal normal 600 14px/18px Inter;
  letter-spacing: 0px;
  padding: 1rem;
}
.table tr:hover {
  background: #2a85fa36 0% 0% no-repeat padding-box;
}
.tableresponsive {
  width: 100%;
  overflow: auto;
}

.action {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.action img {
  cursor: pointer;
  opacity: 0.7;
  padding: 5px 10px;
}
.action img:hover {
  opacity: 1;
  border-radius: 4px;
  background: #fff;
}

.buttonDiv {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  margin-top: 1rem;
}
.grid2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 2rem;
}
