.main {
  /* gap: 1rem; */
  display: grid;
  grid-template-columns: 24% 1fr;
}
.chatMain {
  padding-left: 2rem;
  padding-right: 2rem;
  background: var(--chat);
  overflow-y: auto;
  display: grid;
  grid-template-rows: 74vh auto;
  height: calc(100vh - 48px);
}
.chatMainHeight {
  overflow-y: auto;
}
.chatMainMeta {
  padding: 1rem 2rem;
  background: var(--chat);
  height: calc(100vh - 220px);
  overflow-y: auto;
  /* min-height: fit-content;
  max-height: 34vh; */
}
.chatMainMeta h2 {
  font-size: 16px;
  margin-bottom: 1rem;
  /* position: sticky; */
  top: 18px;
  z-index: 3;
}
.chatMain h2 {
  font-size: 16px;
  margin-bottom: 1rem;
  /* position: sticky; */
  top: 18px;
  z-index: 3;
}
.tab_btn {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.tab_btn button {
  border: 1px solid var(--white);
  opacity: 1;
  height: auto;
  padding: 0.8em;
  font: normal normal medium 16px/20px Inter;
  letter-spacing: 0px;
  color: #233f48;
  opacity: 1;
  cursor: pointer;
}
.btn_active {
  background: #fff;
}
.questionDiv {
  display: flex;
  gap: 1rem;
  max-width: 40%;
  width: fit-content;
  margin-left: auto;
  justify-content: flex-end;
}
.answerDiv {
  display: grid;
  gap: 1rem;
  max-width: 60%;
  width: fit-content;
  align-items: end;
  margin-bottom: 1rem;
  grid-template-columns: auto 1fr;
}
.sqlqueryButton {
  background: var(--blue);
  border-radius: 4px;
  border: navajowhite;
  color: #fff;
  cursor: pointer;
  margin-bottom: 0.3rem;
}
.grid2 {
  display: grid;
  grid-template-columns: auto auto;
}
.sqlquery {
  /* font-size: 12px;
    background: #282323;
    color: #fff;
    margin-bottom: 0.3rem;
    padding: 2px 1rem;
    border-radius: 25px 25px 25px 0px; */

  font-size: 12px;
  /* background: #f1f4f8 0% 0% no-repeat padding-box; */
  /* color: #fff; */
  margin-bottom: 0.3rem;
  padding: 2px 1rem;
  border-radius: 25px 25px 25px 0px;
  border: 2px solid var(--blue);
}
.question {
  background: #f1f4f8 0% 0% no-repeat padding-box;
  border: 2px solid #165db9;
  border-radius: 21px 0px 21px 21px;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  font-size: 12px;
}
.answer {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #0c4590;
  border-radius: 25px 25px 25px 0px;
  padding: 0.5rem 1rem;
  display: flex;
  font-size: 12px;
  overflow-x: auto;
}
.icon {
  width: 22px;
  height: 22px;
  background: #f1f4f8 0% 0% no-repeat padding-box;
  border: 2px solid #0c4590;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 0.4rem;
}
.h {
  transition-duration: 0.15s;
  transition-property: width;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.chatSearch {
  z-index: 3;
  background: #fff;
  padding: 0 1rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 12px;
  opacity: 1;

  align-items: center;
  vertical-align: center;
  gap: 1rem;
  display: grid;
  grid-template-columns: 1fr auto;
}
.chatSearch input {
  height: 40px;
  outline: none;
  border: none;
}
.height {
  overflow-y: auto;
  height: calc(100vh - 48px);
}
.table {
  border-collapse: collapse;
  width: 100%;
}
td,
th {
  text-align: left;
  padding: 8px;
}

.answerChat {
  position: absolute;
  top: 50%;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #0c4590;
  border-radius: 25px 25px 25px 0px;
  padding: 0.5rem 1rem;
  display: flex;
  font-size: 12px;
  overflow-x: auto;
}
.answerChatMeta {
  position: absolute;
  top: 70%;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #0c4590;
  border-radius: 25px 25px 25px 0px;
  padding: 0.5rem 1rem;
  display: flex;
  font-size: 12px;
  overflow-x: auto;
}
.blue {
  color: var(--blue);
  margin: 0;
  margin-bottom: 6px;
}
.bold {
  font-weight: bold;
}
.buttonSend {
  background: transparent;
  border: none;
}
.chatFooter {
  background: #eae8e8;
  padding: 1rem;
  box-shadow: 0px 1px 1px #00000029;
  display: grid;
  align-items: flex-end;
  max-height: 66px;
  border-radius: 20px;
}
.chatFooterh {
  background: #eae8e8;
  padding: 1rem;
  box-shadow: 0px 1px 1px #00000029;
  display: grid;
  align-items: flex-end;
  max-height: 40px;
  border-radius: 20px;
}
.chatFooterEnd label {
  font-size: 12px;
}
