.file {
  font: normal normal 600 16px/20px Inter;
  letter-spacing: 0px;
  color: var(--headingblue);
  opacity: 1;
  margin: 1rem 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.file h1 {
  font: normal normal 600 16px/20px Inter;
  letter-spacing: 0px;
  color: #233f48;
  opacity: 1;
}
.icon {
  width: 22px;
  height: 22px;
}
.buttonDiv svg {
  cursor: pointer;
  color: var(--blue);
}
