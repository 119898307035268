.main {
  display: grid;
  grid-template-columns: 15% 1fr;
  gap: 1rem;
}
.height {
  overflow-y: auto;
  box-shadow: rgb(86 84 84 / 20%) 0px 2px 8px 0px;
  height: calc(100vh - 48px);
}
.bg {
  background: #fff;
  padding: 1rem 2rem;
  margin-bottom: 300px;
}
.chatMain {
  overflow-y: auto;
  display: grid;
}

.file {
  font: normal normal 600 16px/20px Inter;
  letter-spacing: 0px;
  color: var(--headingblue);
  opacity: 1;
  margin: 0rem 0 1rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.file h1 {
  font: normal normal 600 16px/20px Inter;
  letter-spacing: 0px;
  color: #233f48;
  opacity: 1;
}
.input {
  display: grid;
  margin-bottom: 0.5rem;
}

.input select,
textarea {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  padding: 8px;
  border: 1px solid #556cd6;
  margin-bottom: 0.5rem;
}
.input textarea {
  width: 98%;
  background: #efefef;
}
.btngroup {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  margin-top: 1rem;
}
.grid2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}
/* .grid2 label {
  padding: 0.5rem 0;
} */
.mt2 {
  margin-top: 1rem;
}
.mb2 {
  margin-bottom: 1rem;
}

.log thead th {
  font-weight: bold !important;
  background: #f1f4f8 0% 0% no-repeat padding-box;
  color: var(--blue);
  font: normal normal 600 14px / 18px Inter;
  letter-spacing: 0px;
  padding: 1rem;
}
.log thead {
  background-color: var(--main);
}
.bold {
  font-weight: bold;
}
.sqlQuery {
  padding: 1rem;
  background: #efefef;
  margin-top: 1rem;
}
.loader {
  position: absolute;
  left: 50%;
  position: fixed;
  z-index: 1300;
  opacity: 0.9;
}
.flex {
  display: flex;
  gap: 1rem;
}
.cursor {
  cursor: pointer;
}
