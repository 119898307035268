.headerFlex {
  position: sticky;
  top: 0;
  display: flex;
  padding: 0.3rem 2rem;
  align-items: center;
  justify-content: space-between;
  height: 38px;
  background: #f1f4f8 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 1px #00000029;
}
.headerFlexRight {
  display: flex;
  gap: 1rem;
}
.headerHighlight {
  background: #fff;
  border-radius: 50%;
}
.headerFlexRight span {
  cursor: pointer;
  height: 33px;
  width: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.headerFlexRight span:hover {
  background: #fff;
  border-radius: 14px;
}

/* Layout Page */

.layout {
  display: grid;
  grid-template-columns: 70px 1fr;
  gap: 3px;
  height: calc(100vh - 48px);
  overflow-y: auto;
}

/* Side Bar  */

.side_bar_nav {
  background: var(--main);
  box-shadow: rgb(86 84 84 / 20%) 0px 2px 8px 0px;
}
.side_bar_nav svg {
  /* width: 50%; */
}
.side_bar_nav:hover span {
  display: block;
}
.side_bar_nav span {
  display: none;
}
.side_bar_nav a {
  display: flex;
  flex-flow: column;
  text-align: center;
  color: var(--blue);
  padding: 1rem 2rem;
  align-items: center;
  text-decoration: none;
  vertical-align: middle;
  justify-content: center;
  font-weight: bold;
  font-size: 15px;
  min-height: 52px;
}

.side_bar_nav a:hover {
  background: #fff;
}
.active {
  background: #fff;
  border-right: 5px solid;
}
.cursor {
  cursor: pointer;
  width: 110px;
}
.fixedLayout {
  position: fixed;
  right: 10px;
  top: 15px;
  padding: 3px;
  background: transparent;
  border: none;
  cursor: pointer;
  color: var(--blue);
}
.fixedLayout2 {
  position: fixed;
  right: 22px;
  top: 12px;
  padding: 3px;
  background: transparent;
  border: none;
  cursor: pointer;
  color: var(--blue);
}
.fixedLayout:hover {
  opacity: 0.6;
}
.task {
  position: fixed;
  z-index: 44;
  top: 0;
  right: 0;
  background: var(--main);
  width: 50%;
  height: 100vh;
  overflow-y: auto;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.addtask {
  display: flex;
  align-items: center;
  padding: 12px;
  background: var(--btn);
  color: #fff;
  gap: 4px;
  cursor: pointer;
  z-index: 89;
  /* opacity: 0.8; */
}
.add {
  display: grid;
  grid-template-columns: 1fr;
  gap: 4px;
  padding: 10px;
  align-items: flex-end;
  color: var(--btn);
}
.taskButton button {
  background: transparent;
  border: 1px solid var(--btn);
  border-radius: 4px;
  color: var(--btn);
  cursor: pointer;
}
.taskButton {
  display: flex;
  gap: 0.3rem;
  justify-content: flex-end;
  margin-top: 0.3rem;
}
.danger {
  /* border: 1px solid red !important;
  border-radius: 4px;
  color: red !important; */
}
.pos12 {
  position: relative;
  top: -6px;
}
