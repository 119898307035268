.main {
  background: var(--main);
  height: 100vh;
  display: flex;
  align-items: center;
}

.login a {
  text-decoration: none;
}

.logo {
  margin: auto;
  display: block;
  width: 70%;
  background-repeat: no-repeat;
  background-size: cover;
}
.login {
  height: 100vh;
  display: grid;
  align-items: center;
  padding: 2rem 8rem;
  text-align: center;
  overflow-y: auto;
}
.heading {
  font: normal normal normal 18px/21px Inter;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
}
.remember {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.account {
  margin: 0.5rem 0;
}
.accountImg {
  margin: auto;
  width: 40px;
  margin-bottom: 1rem;
}
.accelerated {
  color: var(--blue);
  font-size: 18px;
  margin: 0;
  font-weight: 600;
  position: relative;
  top: -7px;
}
/*  */

@media only screen and (max-width: 600px) {
  .login {
    height: auto;
    padding: 2rem;
  }
  .main {
    height: auto;
  }
}
